
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    contact_data() {
      return this.$store.getters['globals/contact_data']
    },

    contact_button() {
      return this.$store.getters['globals/contact_button']
    },
  },
}
