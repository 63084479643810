import { render, staticRenderFns } from "./Consulting.vue?vue&type=template&id=8245f032&scoped=true&"
import script from "./Consulting.vue?vue&type=script&lang=js&"
export * from "./Consulting.vue?vue&type=script&lang=js&"
import style0 from "./Consulting.vue?vue&type=style&index=0&id=8245f032&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8245f032",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,AnimatedButton: require('/vercel/path0/components/buttons/AnimatedButton.vue').default,Phone: require('/vercel/path0/components/svg/Phone.vue').default,Envelop: require('/vercel/path0/components/svg/Envelop.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
